<template>
  <Header title="our cars" />

  <Container>
    <div class="">
      <div class="max-w-3xl mx-auto text-center">
        <h2 class="mb-4">Driven to exceed your expectation</h2>
        <p class="text-lg text-gray-900 mx-auto max-w-md md:max-w-full">
          DriveUs is Vancouver’s Premier Luxury Chauffeured Car Service. We
          offer the latest and most luxurious vehicles driven by professionally
          trained and locally knowledgeable chauffeurs.
        </p>
      </div>
      <div
        class="mt-12 grid lg:grid-cols-3 gap-8 pt-12 border-t border-accent-darkest"
      >
        <h2 class="mb-4 lg:col-span-3 text-center">Choose your luxury ride</h2>
        <div class="text-center flex flex-col items-center">
          
          <div class="w-full">
            <div class="aspect-h-3 aspect-w-4 relative max-w-lg">
          <img
            src="/cars-img/escalade-ev/index.jpg"
            alt="merc"
            class="object-cover w-full h-full absolute inset-0   py-2"
          />
          </div>
          </div>
          <p class="font-medium text-lg">2025 Cadillac Escalade IQ EV</p>
          <p class="mt-1 font-light">6 Passenger</p>
          <router-link
            to="/cars/cadillac-escalade-ev"
            class="mt-4 btn btn-xl btn-light"
            >View this Car</router-link
          >
        </div>

        <!-- <div class="text-center flex flex-col items-center">
          <div class="w-full">
            <div class="aspect-h-3 aspect-w-4 relative max-w-lg">
              <img
                src="/cars-img/cadillac-ct5/index.jpg"
                alt="merc"
                class="object-contain w-full h-full absolute inset-0"
              />
            </div>
          </div>
          <p class="font-medium text-lg">2024 Cadillac CT5 V Series</p>
          <p class="mt-1 font-light">4 Passenger</p>
          <router-link to="/cars/cadillac-ct5" class="mt-4 btn btn-xl btn-light"
            >View this Car</router-link
          >
        </div> -->
        <div class="text-center flex flex-col items-center">
          <div class="w-full">
            <div class="aspect-h-3 aspect-w-4 relative max-w-lg">
          <img
          src="/cars-img/sprinter/index.jpg"
            alt="merc"
            class="object-contain mix-blend-multiply w-full h-full absolute inset-0"
          />
          </div>
          </div>
          <p class="font-medium text-lg">2024 Mercedes Benz AWD Sprinter Van</p>
          <p class="mt-1 font-light">12 Passenger</p>
          <router-link
            to="/cars/2024-sprinter"
            class="mt-4 btn btn-xl btn-light"
            >View this Car</router-link
          >
        </div>
        <!-- <div class="text-center flex flex-col items-center">
          <img
            src="@/assets/images/merc.jpg"
            alt="merc"
            class="max-w-lg object-cover w-full h-full"
          />
          <p class="font-medium text-lg">2022 Mercedes Benz S580</p>
          <p class="mt-1 font-light">4 Passenger</p>
          <router-link
            to="/cars/mercedes-benz-S580"
            class="mt-4 btn btn-xl btn-light"
            >View this car</router-link
          >
        </div> -->
        <div class="text-center flex flex-col items-center"> 
          <div class="w-full">
            <div class="aspect-h-3 aspect-w-4 relative max-w-lg">
          <img
            src="@/assets/images/caddy-left.jpg"
            alt="caddy"
            
            class="object-cover w-full mix-blend-multiply h-full absolute inset-0   py-2"
          />
          </div>
          </div>
          <p class="font-medium text-lg">
            2023 Cadillac Escalade ESV Platinum Sport
          </p>
          <p class="mt-1 font-light">6 Passenger</p>
          <router-link
            to="/cars/cadillac-escalade-ESV"
            class="mt-4 btn btn-xl btn-light"
            >View this car</router-link
          >
        </div>

        <!-- <div class="text-center flex flex-col items-center">
          <div class="w-full">
            <div class="aspect-h-3 aspect-w-4 relative max-w-lg">
          <img
            src="/cars-img/denali/index-thumb.jpg"
            alt="caddy"
            class="object-cover w-full h-full absolute inset-0   py-2"
          />
          </div>
          </div>
          <p class="font-medium text-lg">2023 GMC Denali XL Ultimate Edition</p>
          <p class="mt-1 font-light">6 Passenger</p>
          <router-link
            to="/cars/gmc-denali-ultimate"
            class="mt-4 btn btn-xl btn-light"
            >View this car</router-link
          >
        </div> -->

        <div class="text-center flex flex-col items-center">
          <div class="w-full">
            <div class="aspect-h-3 aspect-w-4 relative max-w-lg">
          <img
            src="/cars-img/sprinter/index.jpg"
            alt="caddy"
            class="object-cover mix-blend-multiply w-full h-full absolute inset-0   py-2"
          />
          </div>
          </div>
          <p class="font-medium text-lg">
            2022 Mercedes Benz Sprinter Van
          </p>
          <p class="mt-1 font-light">12 Passenger</p>
          <router-link
            to="/cars/mercedes-benz-sprinter"
            class="mt-4 btn btn-xl btn-light"
            >View this car</router-link
          >
        </div>

        <!-- <div class="text-center flex flex-col items-center">
          <div class="w-full">
            <div class="aspect-h-3 aspect-w-4 relative max-w-lg">
          <img
            src="/cars-img/chevrolet/index-thumb.jpg"
            alt="caddy"
            class="object-cover w-full h-full absolute inset-0   py-2"
          />
          </div>
          </div>
          <p class="font-medium text-lg">2023 Chevrolet Suburban RST</p>
          <p class="mt-1 font-light">6 Passenger</p>
          <router-link
            to="/cars/chevrolet-suburban-rst"
            class="mt-4 btn btn-xl btn-light"
            >View this car</router-link
          >
        </div> -->
        <!-- <div class="text-center flex flex-col items-center">
          <img
            src="@/assets/images/caddy-left.jpg"
            alt="caddy"
            class="mix-blend-multiply max-w-lg object-cover w-full h-full"
          />
          <p class="font-medium text-lg">2021 Cadillac Escalade Platinum Sport </p>
          <p class="mt-1 font-light">6 Passenger</p>
           <router-link to="/cars/cadillac-escalade" class="mt-4 btn btn-xl btn-light">View this car</router-link>
        </div> -->
      </div>

      <!-- <div
        class="mt-12 grid lg:grid-cols-3 gap-8 pt-12 border-t border-accent-darkest"
      >
        <h2 class="mb-4 lg:col-span-3 text-center">Coming Soon</h2>
        
      </div> -->
    </div>
  </Container>

  <CarCTA />
</template>

<script>
import FullSplit from "../../components/layouts/FullSplit.vue";
import Container from "../../components/layouts/Container.vue";
import Header from "@/components/base/Header";
import CarCTA from "../../components/layouts/cta/CarCTA.vue";

export default {
  components: {
    Header,
    Container,
    FullSplit,
    CarCTA,
  },
};
</script>
